<template>
  <div>
    <loading :active="progress.isDetailsLoading" :can-cancel="true"></loading>
    <div class="md-layout">
      <div class="md-layout-item">
        <h3 class="md-title m-0">My Messages</h3>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <div class="md-layout msg-layout my-3">
          <div class="md-layout-item md-size-30 md-small-size-100 p-0">
            <div class="msg-left-layout">
              <div class="msg-header">
                <md-button
                  slot="footer"
                  class="md-primary m-0"
                  @click="goToCompose()"
                >
                  New Message
                </md-button>
              </div>
              <div class="msg-list-head">
                <h4 class="title m-0">
                  NEW( <span>{{ count }}</span
                  >)
                </h4>
                <div class="filter-btn">
                  <select-input
                    md-size="small"
                    name="filter"
                    displayName="Filters"
                    placeHolder="Filters"
                    md-menu-trigger
                    :options="
                      messageType.map((x) => ({
                        value: x.lookupId,
                        text: x.displayValue,
                      }))
                    "
                    v-model="Type"
                  />
                </div>
              </div>

              <div class="msg-list scroll">
                <div v-if="length > 0">
                  <div
                    class="msg-list-item"
                    v-for="(msg, index) in filterMsg"
                    :key="index"
                    :class="{ active: index === activeItem }"
                    @click="
                      selectMessage(
                        msg.id,
                        msg.parentMsgID,
                        msg.status,
                        msg.messageType,
                        index
                      )
                    "
                  >
                    <div>
                      <h3 :class="{ 'unread-name': msg.status == msgStatus }">
                        {{ msg.from.name }}
                      </h3>
                      <h4>{{ msg.subject }}</h4>
                      <p v-if="msg.messageType == 606">
                        {{ substrng(msg.body) }}...
                      </p>
                      <p v-if="msg.messageType == 607">
                        {{ subhtml(msg.body) }}...
                      </p>
                    </div>
                    <div>
                      <div class="msg-list-icons" v-if="msg.messageType == 607">
                        <i class="far fa-bell alert-color"></i>
                      </div>

                      <p :class="{ 'unread-date': msg.status == msgStatus }">
                        {{ dateListFormat(msg.createdAt) }}
                        <!-- {{ InboxDate(msg.createdAt) }} -->
                      </p>
                      <!-- <button type="button" aria-hidden="true" class="close">
                      ×
                    </button> -->
                    </div>
                  </div>
                </div>

                <!-- <div class="msg-list-segment">Yesterday</div> -->
                <div
                  v-if="length == 0"
                  class="msg-list-item"
                  style="text-align: center"
                >
                  <!-- <button type="button" aria-hidden="true" class="close">
                    ×
                  </button> -->
                  <span>*&nbsp;&nbsp;NO Messages&nbsp;&nbsp;*</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              md-layout-item md-size-70 md-small-size-100
              p-0
              h-100
              d-flex
              flex-column
            "
          >
            <div class="msg-header"></div>
            <!-- Composing a message -->
            <div class="msg-conpose-main scroll">
              <create-msg v-if="Message1.postData.composemsg"></create-msg>

              <!-- replying a message -->
              <div v-if="replymsg">
                <div class="msg-msg-header">
                  <div class="msg-msg-sub">{{ Message1.postData.subject }}</div>
                </div>
                <div class="msg-area reply">
                  <div class="mail-input">
                    <div class="msg-msg-header">
                      <div class="msg-msg-header-info">
                        <img
                          v-if="Message1.postData.to == userProfileName"
                          :src="profileImage"
                          class="msg-profile-img"
                        />
                        <img
                          v-else
                          src="@/assets/img/avatar.png"
                          class="msg-profile-img"
                        />
                        <div class="msg-msg-header-content">
                          <h4>{{ replyTO }}</h4>
                          <p>{{ Message1.postData.to }}</p>
                          <p>{{ currentDate() }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mail-input">
                    <textarea
                      v-model="Message1.postData.body"
                      type="textarea"
                      class="w-100 h-100p"
                      @submit="SendMessage"
                      placeholder="Message..."
                    ></textarea>
                  </div>
                  <div class="msg-action">
                    <spinner
                      slot="footer"
                      style="margin-bottom: 10px"
                      :diameter="25"
                      v-if="progress.isSending"
                    ></spinner>
                    <md-button
                      v-else
                      slot="footer"
                      class="md-raised md-primary"
                      @click="onSendReply()"
                      ><i class="fa fa-paper-plane"></i>
                      Send
                    </md-button>
                    <md-button
                      slot="footer"
                      class="btn-outline"
                      @click="onCloseReply()"
                    >
                      Discard<i class="fa fa-trash-alt"></i>
                    </md-button>
                  </div>
                </div>
              </div>

              <!-- Displying a particular message -->
              <spinner
                slot="footer"
                style="margin-bottom: 10px"
                :diameter="25"
                v-if="progress.isloaded"
              ></spinner>

              <div v-else-if="!Message1.postData.composemsg">
                <md-content v-if="length == 0" class="md-accent p-2"
                  >No messages found. You can create new messages
                </md-content>
                <md-content v-if="emptypage">
                  <i class="far fa-envelope fa-3x center unreadEnvelope"></i>
                  <h4 v-if="emptypage" class="unread">
                    <!-- <i class="far fa-envelope fa-3x "></i> -->
                    Please select any message to view
                  </h4>
                </md-content>
                <div class="msg-msg-header" v-if="length != 0 && !emptypage">
                  <div class="msg-msg-header-info">
                    <file-view-handler
                      :options="{
                        feature: 'User',
                        featureId: selectedMsg.from.id,
                        category: 'PROFILE',
                      }"
                      v-slot="{ uploadedFiles }"
                    >
                      <img
                        v-if="uploadedFiles[0] != null"
                        :src="uploadedFiles[0].link"
                        class="msg-profile-img"
                      />
                      <img
                        v-else
                        src="@/assets/img/avatar.png"
                        class="msg-profile-img"
                      />
                    </file-view-handler>
                    <div class="msg-msg-header-content">
                      <h4>{{ selectedMsg.from.name }}</h4>
                      <p>{{ selectedMsg.fromID }}</p>
                      <p>{{ selectedDateFormat(selectedMsg.createdAt) }}</p>
                    </div>
                    <div
                      v-if="
                        selectedMsg.messageType != 637 &&
                        selectedMsg.messageType != 607
                      "
                    >
                      <md-button
                        slot="footer"
                        class="md-primary-reply"
                        @click="
                          replyMessage(
                            selectedMsg.id,
                            selectedMsg.from.name,
                            selectedMsg.fromID,
                            selectedMsg.subject,
                            selectedMsg.originID
                          )
                        "
                        ><i class="fa fa-reply blue-color"></i>Reply
                      </md-button>

                      <i
                        class="fa fa-trash-alt"
                        @click="CallDeleteMessage(selectedMsg.id)"
                      ></i>
                    </div>
                  </div>
                  <div class="msg-msg-sub">{{ selectedMsg.subject }}</div>
                </div>
                <div class="msg" v-if="!emptypage">
                  <div
                    class="msg-attach"
                    v-if="
                      attachmentsDetails[0] &&
                      attachmentsDetails[0].featureId == selectedMsg.id
                    "
                  >
                    <a
                      class="msg-attachments"
                      v-bind:href="attachmentsDetails[0].links[0].href"
                      target="_blank"
                      ><img src="@/assets/img/png-file-icon-6.jpg" /><span>{{
                        attachmentsDetails[0].name
                      }}</span></a
                    >
                  </div>

                  <div class="msg-body">
                    <span v-html="selectedMsg.body" />
                    <!-- {{ selectedMsg.body }} -->
                  </div>
                </div>
              </div>

              <!-- Message thread display -->
              <div v-if="thread && !emptypage">
                <div
                  v-for="(smsg, index) in selectedMsgThread.slice(1)"
                  :key="index"
                >
                  <md-content
                    class="md-elevation-2 msg-msg-header"
                    @click="ThreadMsgDetails(smsg)"
                  >
                    <div class="msg-msg-header-info p-2">
                      <img
                        v-if="smsg.imgLink.count >= 0"
                        :src="smsg.imgLink[0].href"
                        class="msg-profile-img"
                      />
                      <img
                        v-else
                        src="@/assets/img/avatar.png"
                        class="msg-profile-img"
                      />
                      <div class="msg-msg-header-content">
                        <div class="d-flex justify-content-between">
                          <h4>{{ smsg.from.name }}</h4>
                          <!-- <div>
                            <i
                              class="fa fa-trash-alt"
                              @click="CallDeleteMessage(selectedMsg.id)"
                            ></i>
                          </div> -->
                        </div>
                        <div class="d-flex justify-content-between">
                          <p>{{ smsg.fromID }}</p>
                          <p>{{ selectedDateFormat(smsg.createdAt) }}</p>
                        </div>
                      </div>
                    </div>
                  </md-content>
                  <!-- <div class="msg">
                    <div class="msg-attach" v-if="attachmentsDetails[0]">
                      <a
                        class="msg-attachments"
                        v-bind:href="attachmentsDetails[0].links[0].href"
                        target="_blank"
                        ><img src="@/assets/img/icon-xl.png" /><span>{{
                          attachmentsDetails[0].name
                        }}</span></a
                      >
                    </div>

                    <div class="msg-body">
                      {{ smsg.body }}
                    </div>
                  </div> -->
                  <div v-if="threads" class="msg-area">
                    <div class="p-2">
                      <p>
                        <b>From:</b> {{ smsg.from.name }}&lt;{{
                          smsg.fromID
                        }}&gt;
                      </p>
                      <p>
                        <b>Sent:</b> {{ selectedDateFormat(smsg.createdAt) }}
                      </p>
                      <p>
                        <b>To:</b> {{ smsg.to.name }}&lt;{{ smsg.toID }}&gt;
                      </p>
                      <p><b>Subject:</b> {{ smsg.subject }}</p>
                      <div
                        class="msg-attach"
                        v-if="
                          attachmentsDetails[0] &&
                          attachmentsDetails[0].featureId == smsg.id
                        "
                      >
                        <a
                          class="msg-attachments"
                          v-bind:href="attachmentsDetails[0].links[0].href"
                          target="_blank"
                          ><img src="@/assets/img/png-file-icon-6.jpg" /><span
                            >{{ attachmentsDetails[0].name }}</span
                          ></a
                        >
                      </div>
                      <div class="msg-body">
                        {{ smsg.body }}
                      </div>
                    </div>
                    <div class="msg-action">
                      <md-button aria-hidden="true" @click="close()">
                        Close
                      </md-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CreateMessage from "./CreateMessage.vue";
import { UTCTimetoLocalTime } from "../../services/common-services/SharedFunctionService";
import FileViewHandler from "../../handlers/common-handlers/FileViewHandler";
export default {
  components: {
    "create-msg": CreateMessage,
    FileViewHandler,
  },
  inject: [
    "app",
    "getMessage",
    "getSentMessage",
    "getSelectedMessage",
    "getMessageThread",
    "getAttachments",
    "GetFilter",
    "Message1",
    "SendMessage",
    "AddAttachment",
    "UpdateMessage",
    "DeleteMessage",
    "app",
    "progress",
    "msgnocount",
  ],
  data() {
    return {
      userProfileName: this.app.userProfile.userName,
      userProfilesID: this.app.userProfile.email,
      length: 0,
      count: 0,
      len: 0,
      replyTO: "",
      messageList: [
        {
          from: {
            name: "",
          },
          createdAt: "01/01/0001 00:00:00 PM",
        },
      ],
      messageSentList: [
        {
          from: {
            name: "",
          },
          createdAt: "01/01/0001 00:00:00 PM",
        },
      ],
      selectedMsg: {
        from: {
          name: "",
        },
        createdAt: "01/01/0001 00:00:00 PM",
      },
      FirstselectedNoti: [{}],
      FirstselectedMsg: [{}],
      givenDate: [],
      selectedMsgThread: [],
      attachmentsDetails: [],
      replymsg: false,
      thread: false,
      threads: false,
      Type: "",
      profileImage: this.app.userProfile.profileImage,
      ThreadDetails: {},
      activeItem: -1,
      msgStatus: "unread",
      emptypage: false,
    };
  },
  mounted() {
    this.getMessage();
    this.GetFilter();
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "messages/GET_MESSAGES") {
        if (state.messages) {
          this.messageList = mutation.payload;
          if (this.messageList.length > 0)
            //this.selectedMsg = this.messageList[0];
            // this.selectMessage(
            //   this.messageList[0].id,
            //   this.messageList[0].parentMsgID,
            //   this.messageList[0].status,
            //   this.messageList[0].messageType,
            //   this.index
            // );
            this.emptypage = true;
          this.length = this.messageList.length;
          this.count = 0;
          this.filterCount;
          this.filterTypes;
        }
      }
    });
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "messages/GET_SENT_MESSAGES") {
        if (state.messages) {
          this.messageSentList = mutation.payload;
          if (this.messageSentList.length > 0)
            this.length = this.messageSentList.length;
          this.selectMessage(
            this.messageSentList[0].id,
            this.messageSentList[0].parentMsgID,
            this.messageSentList[0].status,
            this.messageSentList[0].messageType,
            0
          );
        }
      }
    });
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "messages/GET_SELECTEDMSG") {
        if (state.messages) {
          this.selectedMsg = mutation.payload;
          this.emptypage = false;
        }
      }
    });
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "messages/GET_THREAD") {
        if (state.messages) {
          this.selectedMsgThread = mutation.payload;
          this.thread = true;
        }
      }
    });
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "messages/GET_ATTACHMENTS") {
        if (state.messages) {
          this.attachmentsDetails = mutation.payload;
        }
      }
    });
  },
  computed: {
    ...mapState({
      messageType: (state) => state.master.messageType,
    }),
    filterMsg: function () {
      if (this.Type == 637) return this.messageSentList;
      else
        return this.messageList.filter((msg) => {
          if (msg.messageType == this.Type) {
            return msg;
          } else if (this.Type == "") {
            return msg;
          }
        });
    },
    filterCount: function () {
      return this.messageList.filter((msg) => {
        if (msg.status == "unread") {
          this.count = this.count + 1;
          this.msgnocount.value = this.count;
          return this.count;
        }
      });
    },
    filterTypes: function () {
      return this.messageList.filter((msg) => {
        if (msg.messageType == 606) {
          this.FirstselectedMsg.push(msg);
        } else if (msg.messageType == 607) {
          this.FirstselectedNoti.push(msg);
        }
      });
    },
  },
  watch: {
    Type: function () {
      if (this.Type == 637) {
        this.callSent();
      } else if (this.Type == 606) {
        this.selectMessage(
          this.FirstselectedMsg[1].id,
          this.FirstselectedMsg[1].parentMsgID,
          this.FirstselectedMsg[1].status,
          this.FirstselectedMsg[1].messageType,
          0
        );
      } else if (this.Type == 607) {
        this.selectMessage(
          this.FirstselectedNoti[1].id,
          this.FirstselectedNoti[1].parentMsgID,
          this.FirstselectedNoti[1].status,
          this.FirstselectedNoti[1].messageType,
          0
        );
      }
    },
  },
  methods: {
    counting() {
      if (this.messageList.status == "unread") {
        return (this.count = this.count + 1);
      }
    },
    substrng(body) {
      return body.substr(0, 20);
    },
    subhtml(body) {
      return body.substr(12, 32);
    },
    ThreadMsgDetails(smsg) {
      this.ThreadDetails = smsg;
      this.getAttachments(smsg.id);
      this.threads = true;
    },
    goToCompose() {
      this.Message1.postData.composemsg = true;
      this.Message1.postData.body = "";
      this.Message1.postData.subject = "";
      this.replymsg = false;
      this.threads = false;
      this.thread = false;
      this.activeItem = -1;
    },
    onCloseCompose() {
      this.msgdet = true;
    },
    onCloseReply() {
      this.replymsg = false;
      this.Message1.postData.body = "";
      this.Message1.postData.subject = "";
    },
    onSendReply() {
      this.SendMessage();
      this.replymsg = false;
    },
    close() {
      this.threads = false;
    },
    currentDate() {
      const current = new Date();
      var optionsday = { weekday: "long" };
      var day = new Intl.DateTimeFormat("en-US", optionsday).format(current);
      var optionsmonth = { month: "long" };
      var month = new Intl.DateTimeFormat("en-US", optionsmonth).format(
        current
      );
      var hours = current.getHours();
      var minutes = current.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      const date = `${day},${current.getDate()} ${month} ${current.getFullYear()} ${strTime}`;

      return date;
    },
    dateListFormat(createdAt) {
      const current = new Date(createdAt);
      //console.log(current);
      var optionsmonth = { month: "long" };
      var month = new Intl.DateTimeFormat("en-US", optionsmonth).format(
        current
      );
      const date = `${current.getDate()}-${month}-${current.getFullYear()}`;

      return date;
    },
    selectedDateFormat(createdAt) {
      this.emptypage = false;
      const current = new Date(createdAt);
      var optionsday = { weekday: "long" };
      var day = new Intl.DateTimeFormat("en-US", optionsday).format(current);
      var optionsmonth = { month: "long" };
      var month = new Intl.DateTimeFormat("en-US", optionsmonth).format(
        current
      );
      var strTime = UTCTimetoLocalTime(current);
      const date1 = `${day},${current.getDate()} ${month} ${current.getFullYear()} ${strTime}`;

      return date1;
    },
    CallDeleteMessage(msgID) {
      this.DeleteMessage(msgID).then(() => {
        if (this.messageList.length > 0) this.selectedMsg = this.messageList[1];
      });
    },
    callSent() {
      this.getSentMessage();
    },
    selectMessage(id, parentMsgID, status, type, idx) {
      this.activeItem = idx;
      this.getAttachments(id);

      this.getSelectedMessage(id, type);
      if (status == "unread" && type != 637) {
        this.UpdateMessage(id);

        this.messageList[idx].status = "read";
        if (this.count > 0) {
          this.count--;
          this.msgnocount.value = this.count;
        }
      }
      if (parentMsgID != null) {
        this.getMessageThread(id, type);
        // this.getMessageThread(id).then(() => {
        //   status = "read";
        // });
      }
      this.Message1.postData.composemsg = false;
      this.replymsg = false;
      this.thread = false;
    },
    replyMessage(msgId, fromName, fromID, subject, originID) {
      this.replymsg = true;
      this.Message1.postData.parentMsgID = msgId;
      this.Message1.postData.to = fromID;
      this.Message1.postData.subject = subject;
      this.Message1.postData.originID = originID;
      this.replyTO = fromName;
    },
  },
};
</script>
<style scoped>
.blue-color {
  color: blue;
}
</style>
